export default {
  SYSTEM: {
    translation: {
      authRequest: {
        loginDesc: 'Tребуется ввести адрес корпоративной электронной почты',
      },
      accessOrganizationView: {
        dzo: 'ДЗО',
      },
      reportPage: {
        budget: {
          isShowAllVersionsWithR12: 'Все версии карточек с кодом R12',
        }
      },
    },
  },
  PROJECT: {
    translation: {
      'budgetIntegrationButtonText': 'Получить бюджет из Oracle R12',
      dashboard: {
        segmentUtoch: 'Сегмент_уточ (Бюджетодержатель)',
      },
      ppmRequestWeb: {
        employees: {
          __sectionName: 'Команда',
          roleId: 'Роль',
          user: 'Сотрудник',
        },
      },
      ppmRequestKpiWeb: {
        name: 'Наименование КПЭ',
        plan: 'План по КПЭ',
        fact: 'Факт по КПЭ',
        typeId: 'Тип КПЭ',
        statusId: 'Статус',
        year: 'Год',
        weight: 'Вес КПЭ внутри года, %',
        methodCalc: 'Метод расчета',
        unitType: 'Единица измерения',
        result: 'Результат по итогам выполнения, %',
        dateResult: 'Плановая дата достижения',
        dateEndFact: 'Фактическая дата достижения',
        resultComment: 'Описание достигнутого результата',
      },
      workDataRequest: {
        skup: {
          projectId: 'Проект в СКУП',
          workId: 'Работа СКУП',
          regionId: 'Субьект РФ',
          dateStart: 'Начало',
          dateEndPlan: 'Окончание (план)',
          cnt: 'Кол. выполненных / общее кол.',
          dateEndFact: 'Окончание (факт)',
          percent: 'Процент выполнения',
        },
      },
      employeeDataCreate: {
        cfoId: 'ЦФО/МВЗ',
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данном проекте',
        functionCode: 'Код функции',
      },
      projectVersionData: {
        shortName: 'Краткое наименование проекта',
        stateFundingR12code: '$t(projectVersion.budget.stateFundingR12code)',
        territories: {
          territoryId: 'Территория',
          percent: '% распределения',
        },
        verticals: {
          verticalId: 'Вертикаль',
          percent: '% распределения',
        },
      },
      projectBudgetSourceWeb: {
        r12Code: 'Код проекта в R12',
        total: 'Бюджет проекта, тыс. руб. (ОКВ)',
        okbFreeFunds: 'Незаконтрактованный остаток бюджета (ОКВ), тыс. руб.',
        ddsFreeFunds: 'Незаконтрактованный остаток бюджета (ДДС), тыс. руб.',
        nzs: 'НЗС на конец периода',
        budgetSegment: 'Сегмент_уточ (Бюджетодержатель)',
      },
      budgetPredictionOrderWeb: {
        id: 'Идентификтор заказа',
        num: 'Номер заказа',
        r12code: 'Код проекта в R12',
        workName: 'Блок работ',
        budgetIndicatorId: 'Показатель',
        typeId: 'Заключение заказа/перенос затрат',
        transferItem: 'Код и название проекта/статьи затрат с которых необходим перенос затрат',
        investCategoryId: 'Категория инвестиций',
        docNumber: 'Системный номер',
        subjectDescription: 'Описание предмета заказа',
        contractor: 'Контрагент',
        contractSystemNumber: 'Системный номер рамочного договора',
        isCompetitiveProcedures: 'Наличие конкурсных процедур',
        competitiveProceduresDuration: 'Длительность конкурсных процедур, дни',
        signingDate: 'Срок подписания заказа',
        completionDate: 'Срок поставки/выполнения работ',
        dueDate: 'Срок оплаты',
        transferOkbDate: 'Месяц переноса ОКВ',
        transferDdsDate: 'Месяц переноса ДДС',
        okb: 'Сумма заказа ОКВ, тыс. руб. без НДС',
        dds: 'Сумма заказа ДДС, тыс. руб. с НДС',
        prepay: 'Аванс, % от суммы заказа при наличии',
        prepayDate: 'Месяц перечисления аванса',
        comment: 'Комментарий',
        orderActionId: 'Тип оплаты',
      },
      projectVersion: {
        segment: 'Сегмент заказчика',
        segmentExecutor: 'Сегмент исполнителя',
        manager: '$t(Input manager title)',
        isStateFunding: 'Гос. контракт',
        isDppgfSupport: 'Проект с сопровождением ДППГФ',
        territoryPsr: 'Территория',
        budget: {
          stateFundingR12code: 'Код программы проекта',
          isNoStateFundingR12code: 'Нет кода программы из R12',
          oibda: {
            prediction: 'Прогноз, тыс. руб.',
          },
          sfpFinIndicator: {
            programCode: 'Код программы',
            limitMargin: 'Прибыль от цены, руб: предел (ГК/НПА)',
            limitMarginToExpense: 'Прибыль от себестоимости, руб: предел (ГК/НПА)',
            limitMarginToExpenseRate: '% прибыли от себестоимости: предел (ГК/НПА)',
            limitOwnExpenseRate: '% исполнения собственными силами: предел (ГК/НПА)',
            limitMarginToPriceRate: '% прибыли от цены: предел (ГК/НПА)',
            planMargin: 'Прибыль от цены, руб: план (МРЗ)',
            planMarginToExpense: 'Прибыль от себестоимости, руб: план (МРЗ)',
            planMarginToExpenseRate: '% прибыли от себестоимости: план (МРЗ)',
            planOwnExpenseRate: '% исполнения собственными силами: план (МРЗ)',
            planMarginToPriceRate: '% прибыли от цены: план (МРЗ)',
            factMargin: 'Прибыль от цены, руб: факт',
            factMarginToExpense: 'Прибыль от себестоимости, руб: факт',
            factMarginToExpenseRate: '% прибыли от себестоимости: факт',
            factOwnExpenseRate: '% исполнения собственными силами: факт',
            factMarginToPriceRate: '% прибыли от цены: факт',
          }
        },
        isCommunicationChannels: 'Конфиденциальная информация',
        investDirection: 'Инвестиционное направление',
        investProgram: 'Инвестиционная программа',
        isWithoutProcurement: 'Проект без закупок',
        projectDirection: 'Направление проекта',
      },
      ppmRequestBudgetController: {
        postListBudget: {
          dataList: {
            name: '$t(projectBudgetSourceWeb.name)',
            total: '$t(projectBudgetSourceWeb.total)',
            r12Code: '$t(projectBudgetSourceWeb.r12Code)',
            cfoId: '$t(projectBudgetSourceWeb.cfoId)',
          }
        }
      },
      projectData: {
        jiraCode: '$t(Input jiraCode title)',
        confluenceCode: 'Код в Confluence',
      },
      registerLdapRequest: {
        login: 'Имя пользователя',
        password: 'Пароль',
        roleList: 'Права доступа',
      },
      businessCaseWeb: {
        beneficiarySegment: 'Сегмент бенефициара',
        strategicInitiative: 'Стратегическая инициатива',
        isOpexIndicator: 'Влияние показателя на статьи OPEX',
        opex: {
          businessCaseId: 'Бизнес процесс',
          shppId: 'ШПП',
          r12Id: 'Код статьи в R12',
        },
      },
      budgetIncomeIndicatorData: {
        __name: 'Доходные показатели',
        marginTypeId: 'Тип маржи',
        margin: 'Маржа (GM2)',
        marginZni: 'Маржа GM2 ЗнИ',
        incomeIndicators: {
          __name: 'Источники дохода',
          typeId: 'Тип доходного показателя',
        }
      },
    }
  },
  PROGRAM: {
    translation: {
      projectVersionData: {
        shortName: 'Краткое наименование программы',
      },
      projectBudgetSourceWeb: {
        total: 'Бюджет программы проекта, тыс. руб. (ОКВ)',
      },
      employeeDataCreate: {
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данной программе',
      },
      projectVersion: {
        isWithoutProcurement: 'Программа без закупок',
      },
    }
  },
  PORTFOLIO: {
    translation: {
      projectVersionData: {
        shortName: 'Краткое наименование портфеля',
      },
      projectBudgetSourceWeb: {
        total: 'Бюджет портфеля проекта, тыс. руб. (ОКВ)',
      },
      employeeDataCreate: {
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данном портфеле',
      },
    }
  },
  PPM_REQUEST: {
    translation: {
      projectBudgetSourceWeb: {
        total: 'Бюджет проекта, тыс. руб.',
      },
    },
  },
}