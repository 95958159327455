import i18n from "i18next";
import React from 'react';
import { useLocation } from 'react-router-dom';
import Icon, { ProjectMilestone } from '@/pages/CreateProject/BasicNav/Icon';
import Nav from '@/pages/CreateProject/BasicNav/Nav';
import { getDictObj, isEmptyValues, isNotEmptyValues } from '@/utils';
import { useCheckRight } from '@/utils/hooks';
import { useAppSelector } from '@/utils/typedHooks';
import { ProjectStatus, SHOW_PROJECT_ALL_SECTIONS, UserRight } from "@/config/const";
import { intersection, isEmpty } from "lodash";

const BasicNav = () => {
  const { pathname } = useLocation();
  const checkRight = useCheckRight();
  const [
    projectInfo,
    dictStatus,
    dictStage,
    projectUserRightCodes,
    requiredData,
  ] = useAppSelector(state => [
    state.NewProject.newProjectData,
    state.dict.status,
    state.dict.stage,
    state.NewProject.newProjectData?.userRightCodes || [],
    state.Required[state.NewProject.newProjectData?.id],
  ]);

  const isCreatePage = new RegExp('^/(project|program|portfolio)/create/?').test(pathname);
  if (isCreatePage) {
    return (
      <Nav
        data={[
          {
            title: "Создать",
            url: `/${i18n.t('base')}/create`,
            icon: Icon.ProjectCreate,
          }
        ]}
        isShow={true}
      />
    );
  }
  const checkSection = (section) => requiredData?.sections && projectInfo.id
    ? requiredData.sections.includes(section) && requiredData.accent
    : false;

  const {
    id,
    statusId,
    currentAgreeId,
    version,
    stageId,
    isClosed,
    agreeProcessTypeCode
  } = projectInfo;
  const statusCode = getDictObj(dictStatus, statusId)?.code;
  const projectStageCode = getDictObj(dictStage, stageId)?.code;

  const showFullProject = !isEmpty(intersection(SHOW_PROJECT_ALL_SECTIONS, projectUserRightCodes));

  const isAgreeCommittee = agreeProcessTypeCode === 'COMMITTEE';

  const items: NavItemType[] = [
    {
      isShow: !!currentAgreeId
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE)),
      title: "Согласование",
      url: `/${i18n.t('base')}/${id}/agreement/${currentAgreeId}`,
      icon: Icon.ProjectAgreement,
    },
    {
      title: "Общее",
      url: `/${i18n.t('base')}/${id}`,
      icon: Icon.ProjectCommon,
      checker: '^/(project|program|portfolio)/\\d+/?$',
      invalid: checkSection('COMMON')
    },
    {
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_BUSINESS_CASE),
      title: i18n.t('menu.goal'),
      url: `/${i18n.t('base')}/${id}/businessCase`,
      icon: Icon.ProjectGoal,
      invalid: checkSection('BUSINESS_CASE')
    },
    {
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_EMPLOYEES),
      title: "Команда",
      url: `/${i18n.t('base')}/${id}/team`,
      icon: Icon.ProjectTeam,
      invalid: checkSection('EMPLOYEES')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_RISKS)),
      title: "Риски",
      url: `/${i18n.t('base')}/${id}/risk`,
      icon: Icon.ProjectRisk,
      invalid: checkSection('RISKS')
    },
    {
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_BUDGET),
      title: "Бюджет",
      url: `/${i18n.t('base')}/${id}/budget`,
      icon: Icon.ProjectBudget,
      invalid: checkSection('BUDGET')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_PROJECTS)),
      title: "Компоненты Портфеля/Программы",
      url: `/${i18n.t('base')}/${id}/project`,
      icon: Icon.ProjectChildren,
    },
    {
      isShow: showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_WORKS),
      title: "График работ",
      url: `/${i18n.t('base')}/${id}/work`,
      icon: Icon.ProjectWork,
      invalid: checkSection('WORKS')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_KPI)),
      title: i18n.t('menu.kpi'),
      url: `/${i18n.t('base')}/${id}/kpi`,
      icon: Icon.ProjectKpi,
      invalid: checkSection('KPI')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_MBO)),
      title: "МВО",
      url: `/${i18n.t('base')}/${id}/mbo`,
      icon: Icon.ProjectMbo,
      invalid: checkSection('MBO')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_LESSONS)),
      title: "Извлеченные уроки",
      url: `/${i18n.t('base')}/${id}/lesson`,
      icon: Icon.ProjectLesson,
      invalid: checkSection('LESSONS')
    },
    {
      isShow: statusCode !== ProjectStatus.TECHNICAL_PROJECT && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_ASSIGNMENT)),
      title: "Поручения",
      url: `/${i18n.t('base')}/${id}/assignment`,
      icon: Icon.CommitteeIcon,
    },
    {
      isShow: (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_DOCUMENTS)),
      title: "Документы",
      url: `/${i18n.t('base')}/${id}/documents`,
      icon: Icon.ProjectDocument,
    },
    {
      isShow: (version > 1 || [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode))
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_VERSIONS)),
      title: "Версии",
      url: `/${i18n.t('base')}/${id}/version`,
      icon: Icon.ProjectVersion,
    },
    {
      isShow: [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE, ProjectStatus.COORDINATION].map(ps => ps.toString()).includes(statusCode)
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_STATUS_REPORT)),
      title: "Статус-отчет",
      url: `/${i18n.t('base')}/${id}/status`,
      icon: Icon.ProjectStatus,
    },
    {
      isShow: !isClosed
        && !['CLOSE', 'ASSESSMENT_BENEFITS'].includes(projectStageCode)
        && statusCode !== ProjectStatus.TECHNICAL_PROJECT
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_PROJECT_REPORT)),
      title: i18n.t('menu.initReport'),
      url: `/${i18n.t('base')}/${id}/init-report`,
      icon: Icon.CloseReport,
    },
    {
      isShow: [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode)
        && ['CLOSE', 'ASSESSMENT_BENEFITS'].includes(projectStageCode)
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_CLOSE_REPORT)),
      title: "Отчет о закрытии",
      url: `/${i18n.t('base')}/${id}/close-report`,
      icon: Icon.CloseReport,
    },
    {
      isShow: isAgreeCommittee
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_COMMITTE)),
      title: "Данные на ПК",
      url: `/${i18n.t('base')}/${id}/toCommittee?object=PROJECT`,
      icon: Icon.ProjectAgreement,
    },
    // {
    //   isShow: !!projectInvestmentCommitteeId
    //     && projectInvestmentCommitteeId === id
    //     && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_COMMITTE)),
    //   title: "Согласование на ПИК",
    //   url: `/${i18n.t('base')}/${id}/committee?object=PROJECT_INVESTMENT`,
    //   icon: Icon.ProjectAgreement,
    // },
    // {
    //   isShow: !!steeringCommitteeId
    //     && steeringCommitteeId === id
    //     && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_UK)),
    //   title: "Согласование на УК",
    //   url: `/${i18n.t('base')}/${id}/committee?object=STEERING`,
    //   icon: Icon.ProjectAgreement,
    // },
    {
      isShow: (isNotEmptyValues(projectInfo.meetingPlanId)
        || isNotEmptyValues(projectInfo.meetingUnplannedId))
        && projectInfo.isKeyProject
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_AGREE_UK_MEETING)),
      title: "Согласование проведения УК",
      url: `/${i18n.t('base')}/${id}/meeting/${projectInfo.meetingPlanId || projectInfo.meetingUnplannedId}`,
      icon: Icon.ProjectAgreement,
    },
    {
      isShow: isEmptyValues(projectInfo.meetingUnplannedId)
        && projectInfo.isKeyProject
        && checkRight('MEMBER_MEETING_UK', 'ROLE_PROJECT_OFFICE_PRESIDENT')
        && showFullProject,
      title: "Инициировать внеплановое заседание УК",
      url: `/${i18n.t('base')}/${id}/meeting/unscheduled`,
      icon: Icon.ProjectAgreement,
    },
    {
      isShow: projectInfo.isKeyProject
        && (version > 1 || [ProjectStatus.ARCHIVE, ProjectStatus.RELEASE].map(ps => ps.toString()).includes(statusCode))
        && (showFullProject || checkRight(UserRight.SHOW_PROJECT_SECTION_STATUS_REPORT_UK)),
      title: "Статус-отчет УК",
      url: `/${i18n.t('base')}/${id}/meeting/report`,
      icon: Icon.ProjectStatus,
    },
  ];

  return (
    <Nav data={items} isShow={id !== undefined} />
  );
};

export default BasicNav;
