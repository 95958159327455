import React, { useRef } from "react";
import { DropTargetMonitor, XYCoord, useDrop } from "react-dnd";
import {
  CalendarType,
  CalendarTypeData,
  ItemType,
  ItemTypes,
  SAUSAGE_HEIGHT,
  SAUSAGE_TOP
} from '@/components/Gantt/const';
import GanttItem, { GanttItemPure } from "@/components/Gantt/elements/GanttItem";
import { WeekendUtilType, useWeekendUtil } from "@/components/Gantt/util/dateUtil";
import { checkNotBeforeParent, fixByLinks, fixGroup } from '@/components/Gantt/util/linkUtil';
import {
  calcAppendWith, findAndUpdate, flatten, getAutoFakeIndex, getDuration, getLinkType
} from '@/components/Gantt/util/utils';
import { getDictCodeById, getDictIdByCode, isEmptyValues, momentToSelectDate, parseDate, prevent } from "@/utils";
import { useAppSelector } from "@/utils/typedHooks";
import { useShowModal } from '@/utils/hooks';
import { keyBy } from 'lodash';
import { checkMilestone } from "@/pages/CreateProject/Blocks/utils";
import moment from "moment/moment";

const calcDelta = (x: number, calendarType: CalendarType) => {
  const width = CalendarTypeData[calendarType].width;
  return Math.round(x / width);
};

const updateWork = (
  deltaDays: number,
  type: ItemType,
  oldItem: GanttTableItem,
  weekendUtil: WeekendUtilType
): GanttTableItem => {
  if (type === ItemTypes.SAUSAGE) {
    const newDateStart = parseDate(oldItem.dateStart).add(deltaDays, 'day');
    const newDateEnd = weekendUtil.getDateEnd(newDateStart, oldItem.duration);

    const result = {
      ...oldItem,
      dateStart: momentToSelectDate(newDateStart),
      dateEnd: momentToSelectDate(newDateEnd),
      isLocalSaved: true,
    };

    return result;
  }

  if (type === ItemTypes.RESIZE_LEFT) {
    const realDuration = getDuration(parseDate(oldItem.dateStart), parseDate(oldItem.dateEnd));
    const sub = (realDuration + deltaDays) < 1 ? -realDuration + 1 : deltaDays;
    const newDateStart = parseDate(oldItem.dateStart).subtract(sub, 'day');

    return {
      ...oldItem,
      dateStart: momentToSelectDate(newDateStart),
      duration: weekendUtil.getDuration(newDateStart, parseDate(oldItem.dateEnd)),
      isLocalSaved: true,
    };
  }

  if (type === ItemTypes.RESIZE_RIGHT) {
    const realDuration = getDuration(parseDate(oldItem.dateStart), parseDate(oldItem.dateEnd));
    const realDurationDelta = (realDuration + deltaDays) < 1 ? 1 : realDuration + deltaDays;
    const newDateEnd = parseDate(oldItem.dateStart).add(realDurationDelta - 1, 'days');

    return {
      ...oldItem,
      dateEnd: momentToSelectDate(newDateEnd),
      duration: weekendUtil.getDuration(parseDate(oldItem.dateStart), newDateEnd),
      isLocalSaved: true,
    };
  }
};

const getDelta = (type: string, monitor: DropTargetMonitor, calendarType: CalendarType) => {
  let deltaX = null;

  if (type === ItemTypes.SAUSAGE) {
    deltaX = monitor.getDifferenceFromInitialOffset().x;
  }

  if (type === ItemTypes.RESIZE_LEFT || type === ItemTypes.RESIZE_RIGHT) {
    deltaX = calcAppendWith(
      monitor.getInitialSourceClientOffset(),
      monitor.getSourceClientOffset(),
      type,
      calendarType
    );
  }

  return calcDelta(deltaX, calendarType);
};

interface SausageItem {
  data: GanttTableItem;
  type: ItemType;
}

const updateSausage = (
  item: SausageItem,
  monitor: DropTargetMonitor,
  data: GanttTableItem[],
  setData: UpdateFunc<GanttTableItem[]>,
  calendarType: CalendarType,
  weekendUtil: WeekendUtilType,
  links: GanttTableLink[],
  showModal: (message: string, withThrow?: boolean, options?: any) => void,
) => {
  if (item.data.isFromOtherProject) {
    return;
  }

  const updateItem = (oldItem: GanttTableItem) => updateWork(deltaDays, item.type, oldItem, weekendUtil);

  const deltaDays = getDelta(item.type, monitor, calendarType);

  //Проверим не стала ли работа раньше родителя
  try {
    const currentItem = keyBy(flatten(data), 'id')[item.data.id];
    const updatedItem = updateItem(currentItem);
    checkNotBeforeParent(updatedItem, data, links, weekendUtil);
  } catch (e: any) {
    showModal(e, true);
  }

  setData(oldData => {
    let result = findAndUpdate(oldData, item.data.id, updateItem);

    result = fixByLinks(item.data.id, result, links, weekendUtil);
    result = fixGroup(result, weekendUtil);

    return result;
  });
};

const getTargetPosition = (offset: XYCoord, ref: HTMLElement): XYCoord => {
  const { x, y } = offset;
  const { top, left } = ref.getBoundingClientRect();
  return {
    x: Math.round(x - left),
    y: Math.round(y - top)
  };
};

const updateConnect = (
  item: SausageItem,
  positions: PositionGanttItemsType,
  monitor: DropTargetMonitor,
  targetPosition: XYCoord,
  workLinkType: any,
  data: GanttTableItem[],
  setData: any,
  weekendUtil: WeekendUtilType,
  links: GanttTableLink[],
  isEditLinkedWorks: boolean,
  showModal: (message: string, withThrow?: boolean, options?: any) => void,
) => {
  const toElementPosition = getIdByPosition(positions, targetPosition);

  if (isEmptyValues(toElementPosition) || item.data.id === toElementPosition.id) {
    return;
  }

  const toItem = data.find(i => i.id === toElementPosition.id);

  if (item.data.isFromOtherProject && toItem.isFromOtherProject) {
    return;
  }

  isEditLinkedWorks = isEditLinkedWorks && !item.data.isFromOtherProject && !toItem.isFromOtherProject;

  const linkType = getLinkType(
    monitor.getItemType() === ItemTypes.CONNECT_LEFT,
    targetPosition.x - toElementPosition.x < toElementPosition.width / 2
  );

  const link = {
    id: getAutoFakeIndex(),
    fromId: item.data.id,
    fromProjectId: item.data.projectId,
    toId: toItem.id,
    toProjectId: toItem.projectId,
    type: linkType,
    typeId: getDictIdByCode(workLinkType, linkType)
  };

  //Проверим не стала ли работа раньше родителя
  if (isEditLinkedWorks) {
    try {
      const currentItem = keyBy(flatten(data), 'id')[item.data.id];
      checkNotBeforeParent(currentItem, data, [...links, link], weekendUtil);
    } catch (e: any) {
      showModal(e, true);
    }
  }

  setData(oldData => {
    let result = oldData;
    if (isEditLinkedWorks) {
      result = fixByLinks(item.data.id, oldData, [...links, link], weekendUtil, true);
    }

    result = fixGroup(result, weekendUtil);

    return result;
  }, oldLinks => ([...oldLinks, link]));
};

const getIdByPosition = (positions: PositionGanttItemsType, { x, y }: XYCoord) => {
  return Object.values(positions).find(({ work }) => {
    const [x1, y1, x2, y2] = [work.x, work.y, work.x + work.width, work.y + SAUSAGE_HEIGHT];
    return (x1 < x && x < x2) && (y1 < y && y < y2);
  })?.work;
};

export interface GanttContainerProps {
  data: GanttTableItem[];
  width: number;
  setData: any;
  positions: PositionGanttItemsType;
  links: GanttTableLink[];
  calendarType: CalendarType;
  setOpenWorkId: UpdateFunc<number>;
  isEdit: boolean;
  isEditLink: boolean;
}

const GanttContainer = ({
  data, width, setData, positions, links, calendarType, setOpenWorkId, isEdit, isEditLink
}: GanttContainerProps) => {
  const ref = useRef(null);
  const weekendUtil = useWeekendUtil();
  const showModal = useShowModal();
  const [workLinkType, workStatusDict] = useAppSelector(state => [state.dict.workLinkType, state.dict.workStatus]);

  const acceptEditTypes = [];
  if (isEdit) {
    acceptEditTypes.push(ItemTypes.SAUSAGE);
  }
  if (isEditLink) {
    acceptEditTypes.push(ItemTypes.CONNECT_LEFT);
    acceptEditTypes.push(ItemTypes.CONNECT_RIGHT);
  }

  const [, dropRef] = useDrop(
    () => ({
      accept: acceptEditTypes,
      drop: (item: any, monitor) => {
        switch (monitor.getItemType()) {
          case ItemTypes.SAUSAGE:
            updateSausage(item, monitor, data, setData, calendarType, weekendUtil, links, showModal);
            break;
          case ItemTypes.CONNECT_LEFT:
          case ItemTypes.CONNECT_RIGHT:
            updateConnect(item, positions, monitor, getTargetPosition(monitor.getClientOffset(), ref.current),
              workLinkType, data, setData, weekendUtil, links, isEdit, showModal);
            break;
          default:
            break;
        }
      },
    }), [positions, links]);

  dropRef(ref);

  return (
    <div className="gantt-items" ref={!isEdit && !isEditLink ? undefined : ref} style={{
      height: (SAUSAGE_HEIGHT) * data.length,
      width
    }}>
      {data.map(item => {
        if (!positions[item.id]) {
          return;
        }

        const positionWork = positions[item.id].work;
        const positionDateEndInit = positions[item.id].dateEndInitial;
        const positionDateEndFact = positions[item.id].dateEndFact;

        const isMilestone = checkMilestone(item);

        const getMilestoneColorClass = () => {
          if (!isMilestone) {
            return {classPlan: '', classFact: ''}
          }

          let classPlan = '';

          const today = moment();
          const parsedStart = parseDate(item.dateStart);
          const parsedEndFact = parseDate(item.dateEndFact);

          const isTodayAfterPlan = !parsedEndFact
            && today.isAfter(parsedStart, 'day');

          const isFactAfterPlan = parsedEndFact
            && parsedEndFact.isAfter(parsedStart, 'day');

          const closedInTime = parsedEndFact
            && parseDate(parsedStart).isAfter(parsedEndFact, 'day');

          const closedSameDay = parsedEndFact
            && parseDate(parsedStart).isSame(parsedEndFact, 'day');

          const isOverdue = isTodayAfterPlan || isFactAfterPlan;
          const isCanceled = 'CANCEL' === getDictCodeById(workStatusDict, item.statusId);

          if (isOverdue) {
            classPlan = 'red';
          }
          if (closedInTime) {
            classPlan =  'blue';
          }
          if (closedSameDay) {
            classPlan = 'green';
          }
          if (isCanceled) {
            classPlan = '';
          }
          return {classPlan: classPlan, classFact: 'green'}
        }

        const milestoneColorClass = getMilestoneColorClass();

        return (
          <React.Fragment key={item.id}>
            {positionDateEndInit && isMilestone && (
              <GanttItemPure
                key={positionDateEndInit.id}
                item={{ name: 'Базовый срок (план)',
                    customClass: ['milestone', 'light-gray'] } as GanttTableItem}
                left={positionDateEndInit.x}
                top={positionDateEndInit.y + SAUSAGE_TOP}
                width={positionDateEndInit.width}
                onClick={() => {}}
                readonly={true}
              />
            )}
            {positionDateEndFact && isMilestone && (
              <GanttItemPure
                key={positionDateEndFact.id}
                item={{ name: 'Окончание (факт)',
                  customClass: ['milestone'].concat(milestoneColorClass.classFact) } as GanttTableItem}
                left={positionDateEndFact.x}
                top={positionDateEndFact.y + SAUSAGE_TOP}
                width={positionDateEndFact.width}
                onClick={() => {}}
                readonly={true}
              />
            )}
            {positionWork && (
              <GanttItem
                key={positionWork.id}
                data={{
                  ...item,
                  customClass: (item.customClass && !isMilestone ? item.customClass : [])
                    .concat(item.cpm ? 'CPM' : '')
                    .concat(milestoneColorClass.classPlan)}}
                position={positionWork}
                onClick={prevent(() => setOpenWorkId(item.id))}
                readonly={!isEdit && !isEditLink}
              />
            )}
            {positionDateEndInit && !isMilestone && (
              <GanttItemPure
                key={positionDateEndInit.id}
                item={{ name: 'Базовый срок (план)', customClass: ['date-end-init'] } as GanttTableItem}
                left={positionDateEndInit.x}
                top={positionDateEndInit.y + SAUSAGE_TOP}
                width={positionDateEndInit.width}
                onClick={() => {}}
                readonly={true}
              />
            )}
          </React.Fragment>
        )})}
    </div>
  );
};

export default GanttContainer;