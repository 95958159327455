import cx from 'classnames';
import { isFunction } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-modern-calendar-datepicker';

import InputTitle from '@/components/InputTitle';
import { FORMAT_DATE } from '@/config/const';
import { isBetweenDate } from '@/pages/Report/TeamLoad';
import { isEmptyValues, momentToSelectDate, prevent } from '@/utils';
import { useAppSelector } from '@/utils/typedHooks';

export const isRange = (value) => {
  return value && Object.keys(value).some(v => ['from', 'to'].includes(v));
};

const parseDate = (value) => moment(value, ['DD.MM.YYYY']);

const parseHolidayDate = (value) => moment(value, ['YYYY.MM.DD']);

const formatDateRange = (value) => {
  if (value && !isRange(value)) {
    return moment([value.year, value.month - 1, value.day]).format(FORMAT_DATE);
  }

  if (value && !isEndFilling(value)) {
    return 'Завершите выбор';
  }

  if (!value || !value.from || !value.to) {
    return '';
  }

  const { from, to } = value;
  return moment([from.year, from.month - 1, from.day]).format(FORMAT_DATE)
    + ' - '
    + moment([to.year, to.month - 1, to.day]).format(FORMAT_DATE);
};

const isFilled = (val) => {
  return isRange(val) ? val && (val.from || val.to) : val;
};

export const isEndFilling = (val) => {
  if (!isRange(val)) {
    return true;
  }

  return (val.from !== null && val.to !== null)
    || (val.from === null && val.to === null);
};
const minDateDefault = momentToSelectDate(moment().subtract(100, 'year').startOf('year'));
const maxDateDefault = momentToSelectDate(moment().add(50, 'year').endOf('year'));

const DateRangeSelect = ({
  picker = "date",
  ...props
}: {
  picker?: "month" | "date";
  onChange: (val: DateServer) => void;
  minimumDate?: DateServer;
  [other: string]: any;
}) => {
  const [dateRange, setDateRange] = useState({
    openCalendar: () => { }
  });
  const [localValue, setLocalValue] = useState(props.value);
  const [customValue, setCustomValue] = useState();
  const { isHideIcon, isHideClear } = props;
  const isDisabled = props.readonly || props.disabled;
  const minimumDate = props.minimumDate || minDateDefault;
  const maximumDate = props.maximumDate || maxDateDefault;
  const shouldHighlightWeekends = props.shouldHighlightWeekends != null ? props.shouldHighlightWeekends : true;
  const [
    holidays,
    workingDays
  ] = useAppSelector(state => [
    state.dict.holidays.data.filter(item => item["isHoliday"] === true),
    state.dict.holidays.data.filter(item => item["isHoliday"] === false)
  ]);

  let holidayArr = holidays.map(function (item) {
    return momentToSelectDate(parseHolidayDate(item['date']));
  });

  let workingDaysArr = workingDays.map(function (item) {
    return momentToSelectDate(parseHolidayDate(item['date']));
  });

  for (let i = 0; i < holidayArr.length; i++) {
    holidayArr[i]['className'] = 'holiday';
  }

  for (let i = 0; i < workingDaysArr.length; i++) {
    workingDaysArr[i]['className'] = 'workingDay';
  }

  const onChange = (value) => {
    if (!isEndFilling(value)) {
      setLocalValue(value);
      return;
    }

    if (isFunction(props.onChange)) {
      props.onChange(value);
    }

    setLocalValue(value);
  };

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const clear = () => {
    if (isRange(localValue)) {
      const val = props.clearDefault ? props.clearDefault : {
        from: null,
        to: null
      };

      onChange(val);
    } else {
      onChange(null);
    }
  };

  const onFocus = () => {
    dateRange.openCalendar();
  };

  const CustomInput = ({ ref }) => {
    const currentValue = customValue === undefined
      ? (props.formatDateRange || formatDateRange)(localValue)
      : customValue;

    const onChangeCustom = (e) => {
      const value = e.target.value;

      if (isEmptyValues(value) && !isEmptyValues(currentValue)) {
        if (props.isClearable) {
          setCustomValue(undefined);
          clear();
        }
        return;
      }

      if (isRange(localValue)) {
        const checker = /(\d{2}.\d{2}.\d{4})\s*-\s*(\d{2}.\d{2}.\d{4})/;
        const isValid = checker.test(value);
        if (isValid) {
          const [, dateStart, dateEnd] = checker.exec(value);
          let [dateStartParsed, dateEndParsed] = [parseDate(dateStart), parseDate(dateEnd)];
          if (isValid && dateStartParsed.isValid() && dateEndParsed.isValid()) {
            if (dateStartParsed.isAfter(dateEndParsed)) {
              [dateStartParsed, dateEndParsed] = [dateEndParsed, dateStartParsed];
            }

            setCustomValue(undefined);
            onChange({
              from: momentToSelectDate(dateStartParsed),
              to: momentToSelectDate(dateEndParsed),
            });
            ref.current.blur();
            return;
          }
        }

      } else {
        const isValid = /\d{2}.\d{2}.\d{4}/.test(value);
        if (isValid) {
          const date = parseDate(value);
          if (date.isValid() && isBetweenDate(minimumDate, maximumDate, momentToSelectDate(date))) {
            setCustomValue(undefined);
            onChange(momentToSelectDate(date));
            ref.current.blur();
            return;
          }
        }
      }

      setCustomValue(value);
    };

    const onBlurCustom = () => {
      setCustomValue(undefined);
    };

    return (
      <input
        id={props.id}
        className={cx('DatePicker__input -ltr ', {disabled: isDisabled}, props.className)}
        type="text"
        ref={ref}
        value={currentValue}
        onChange={onChangeCustom}
        onBlur={onBlurCustom}
      />
    );
  };

  return (
    <InputTitle
      required={props.required}
      tooltip={props.tooltip}
      isClearable={props.isClearable}
      label={props.label}
      value={isFilled(localValue) || customValue}
      description={props.description}
      className={cx(props.titleClassName, 'input-date', { mini: props.mini, 'with-hide-icon': isHideIcon })}
      readonly={isDisabled}
      onClick={onFocus}
      isNoFocus={true}
    >
      <div className={cx("date-rt__container", { 'pointer-events-none': isDisabled })}>
        <DatePicker
          refCallback={setDateRange}
          value={localValue}
          onChange={onChange}
          locale={'ru'}
          wrapperClassName="rt-calendar"
          shouldHighlightWeekends={shouldHighlightWeekends}
          formatInputText={() => (props.formatDateRange || formatDateRange)(localValue)}
          colorPrimary="var(--primary-color)"
          colorPrimaryLight="rgba(16, 24, 40, 0.05)"
          inputPlaceholder={props.inputPlaceholder}
          minimumDate={minimumDate}
          maximumDate={maximumDate}
          picker={picker}
          renderInput={CustomInput}
          customDaysClassName={holidayArr.concat(workingDaysArr)}
          isPortal={props.isPortal || false}
          portalScrollClasses={props.portalScrollClasses}
        />
        <div className="date-rt__indicators">
          {!isHideClear && props.isClearable && isFilled(localValue) && (
            <div onClick={prevent(clear)} aria-hidden="true" className={cx('select-rt__indicator select-rt__clear-indicator', {'table': props.isInTable})}>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="select-rt__clear-indicator-svg">
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </div>
          )}
          {!isHideIcon && (
            <>
              <span className="select-rt__indicator-separator"></span>
              <div aria-hidden="true" className="select-rt__indicator select-rt__dropdown-indicator">
                <svg width="20" height="20" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.5 4.5V6.5H17V4.5H18C18.8284 4.5 19.5 5.17157 19.5 6V8.01562L4.5 8.01562V6C4.5 5.17157 5.17157 4.5 6 4.5H7V6.5H8.5V4.5L15.5 4.5ZM4.5 9.51562L4.5 18C4.5 18.8284 5.17157 19.5 6 19.5H18C18.8284 19.5 19.5 18.8284 19.5 18V9.51562L4.5 9.51562ZM6 3H7V1H8.5V3L15.5 3V1H17V3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3Z" />
                </svg>
              </div>
            </>
          )}
        </div>
      </div>
    </InputTitle>
  );
};

export default DateRangeSelect;
