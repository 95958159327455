import cx from 'classnames';
import i18n from "i18next";
import React, { useRef } from 'react';
import { createNewItem } from '@/components/Gantt';
import TableDragable from '@/components/Gantt/components/TableDragable';
import { AppendIcon, EditIcon, EyeIcon, UpDownIcon } from '@/components/Gantt/elements/Icons';
import { findAndUpdate, isRemoved } from '@/components/Gantt/util/utils';
import { getShortName } from '@/components/UserSearch';
import UserSearchInTable from '@/components/UserSearch/UserSearchInTable';
import { FORMAT_DATE, WorkType } from '@/config/const';
import Input from '@/elements/Input';
import DateSelect from '@/elements/Input/DateSelect';
import SelectDict from '@/elements/Select/SelectDict';
import { dateEndClass, dateStartClass } from '@/pages/CreateProject/Blocks/TableResultWorks';
import { checkGroup, checkMilestone, checkTask, getWorkNameWithProject } from '@/pages/CreateProject/Blocks/utils';
import { createEditable } from '@/components/TableBeta/utils';
import {
  formatDate,
  formatDays, getDict,
  getDictObj, getProjectRoleOptionsList,
  isBeforeToday,
  momentToSelectDate,
  parseDate,
  prevent
} from '@/utils';
import { useAppSelector } from '@/utils/typedHooks';
import { useShowModal } from "@/utils/hooks";
import { WORK_NEED_DOC_MESSAGE } from "@/pages/CreateProject/Blocks/EditBlock";
import Tooltip from '@/elements/Tooltip';
import { isEmpty } from 'lodash';
import moment from "moment";

const getRowProps = (row) => {
  if (isRemoved(row.original)) {
    return {
      style: {
        display: 'none'
      }
    };
  }

  return undefined;
};

export const setFocusToTaskName = (id: number, isSelect = true) => {
  setTimeout(() => {
    const item = (document.querySelector(`[data-task-id="${id}"] .gantt-row-title .inputField`) as any);
    item?.focus();
    isSelect && item?.select();
  }, 100);
};

export const setFocusTo = (id, evenTargetSelector, focusSelector) => {
  setTimeout(() => {
    try {
      const item = (document.querySelector(`[data-task-id="${id}"] ${evenTargetSelector} ${focusSelector}`) as any);
      item?.focus();
    } catch (e) {
      //
    }
  }, 100);
};

const getColumns = ({
  readonly,
  isEditRight,
  dict,
  projectData,
  showModal,
  maxDepth,
  setData,
  setExpanded,
  setOpenWorkId,
  setEditRowId,
  editRowId,
  expandAll,
  isAllNotExpanded
}) => {
  const result: any[] = [];

  const isEditTable = !readonly && isEditRight;
  const isDraft = getDictObj(dict.status, projectData.statusId)?.code === 'DRAFT';

  const getMinDate = (work) => {
    if (checkGroup(work)) {
      return null;
    }

    return projectData.dateStart;
  }

  const getMaxDate = () => {
    return projectData.dateEnd;
  }

  const createNewItemInGroup = (id: number) => {
    let newItem;
    setData(oldData => {
      return findAndUpdate(oldData, id, (item) => {
        const newDateStart = momentToSelectDate(parseDate(item.dateStart));
        item.subRows ||= [];
        item.typeId = WorkType.GROUP;
        newItem = createNewItem({
          workGroupId: id,
          dateStart: newDateStart,
          projectId: projectData.projectId,
          projectVersionId: projectData.id,
        });
        item.subRows.push(newItem);

        return item;
      });
    });

    setExpanded(oldExpanded => ({
      ...oldExpanded,
      [`${id}`]: true
    }));

    setTimeout(() => {
      setOpenWorkId(null);
      setEditRowId(newItem.id);
      setFocusToTaskName(newItem.id);
    }, 100);
  };

  const dragTooltip = (children = undefined, delay = undefined) =>
    <Tooltip
      children={children}
      delay={delay}
      text={<span>
        <b>Потяните</b> стрелочки для интерактивного перемещения блока работ<br/>
        <b>Двойной клик</b> по стрелочкам для выбора новой позиции блока работ
    </span>}
    />;

  if (!readonly && isEditTable) {
    result.push({
      id: 'remove',
      Header: ({ props }) => {
        return (
          <input
            checked={props.isCheckedAll}
            onChange={() => props.onToggleCheckedAll(!props.isCheckedAll)}
            type="checkbox"
          />
        );
      },
      width: 30,
      Cell: ({ row, props }) => {
        return (
          <input
            checked={props.checked[row.original.id] || false}
            onChange={() => props.onToggleChecked(row.original.id)}
            type="checkbox"
            title="Выделить блок работ"
          />
        );
      }
    });
  }

  result.push({
    Header: "",
    width: 15 * maxDepth + 16,
    accessor: 'rowNum',
    Cell: ({value}) => value || '',
  });

  if (isEditTable) {
    result.push({
      id: 'append',
      Header: '',
      width: 24,
      className: 'icon-cell',
      Cell: ({ row }) => {
        if (row.original.isFromOtherProject || row.original.isExtreme) {
          return null;
        }

        return (
          <div>
            <AppendIcon className="link" onClick={prevent(() => createNewItemInGroup(row.id))} />
          </div>
        );
      }
    });

    result.push({
      id: 'edit',
      Header: "",
      width: 24,
      className: 'icon-cell',
      Cell: ({ row }) => {
        if (row.original.isFromOtherProject) {
          return <EyeIcon className="link" onClick={prevent(() => setOpenWorkId(row.id))} />
        }

        return (
          <EditIcon className="link" onClick={prevent(() => setOpenWorkId(row.id))} />
        );
      }
    });
  }

  if (!readonly) {
    result.push({
      width: 24,
      className: 'icon-cell',
      id: 'dragable',
      Header: () => dragTooltip(),
      headerStyle: { paddingLeft: 0, paddingRight: 0 },
      Cell: ({ row }) => {
        if (row.original.isExtreme) {
          return null;
        }
        return <UpDownIcon/>
      },
      dragable: true,
    });
  }

  result.push(...[
    {
      Header: () => {
        const getIcon = () => {
          if (isAllNotExpanded) {
            return <span><b>→</b> {i18n.t('workDataRequest.name')}</span>
          }
          return <span><b>↓</b> {i18n.t('workDataRequest.name')}</span>
        }

        return (
          <div onClick={prevent(expandAll)}>
            <Tooltip icon={getIcon()} text={isAllNotExpanded ? 'Развернуть все' : 'Свернуть все'}></Tooltip>
          </div>
        );
      },
      width: 300,
      accessor: 'name',
      resizable: true,
      Cell: (args) => {
        const { row, value } = args;
        const name = row.original.isFromOtherProject
          ? getWorkNameWithProject(row.original, dict.types)
          : (isEditTable && editRowId === row.id ? createEditable(Input)(args) : value);
        const title = row.original.isFromOtherProject ? getWorkNameWithProject(row.original, dict.types) : value;

        if (!row.canExpand || row.original.isExtreme) {
          return (
            <span
              style={{
                paddingLeft: `${row.depth * 16}px`,
              }}
              className="gantt-row-title"
            >
              <div className="text" title={title}>
                {name}
              </div>
            </span>
          );
        }

        return (
          <span
            style={{
              paddingLeft: `${row.depth * 16}px`,
            }}
            className="gantt-row-title"
          >
            <span
              {...row.getToggleRowExpandedProps({
                title: row.isExpanded ? 'Свернуть группу' : 'Развернуть группу',
                className: cx('canExpand js-no-event', { expanded: row.isExpanded }),
                onClick: prevent(() => row.toggleRowExpanded()),
              })}
            >
            </span>
            <div className="text js-no-event" title={title}>
              {name}
            </div>
          </span>
        );
      },
    },
    {
      Header: i18n.t('workDataRequest.statusId'),
      width: 110,
      accessor: 'statusId',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original)
          || args.row.original.isFromOtherProject) {
          return <span className='gantt-row-text'>{getDict(dict.workStatus, args.row.original.statusId)}</span>
        }

        return createEditable(SelectDict, {
          dictName: 'workStatus',
          placeholder: checkGroup(args.row.original) ? '' : "Выберите статус",
          filterFunc: item => checkMilestone(args.row.original) ? item.isMilestone : item.isWork,
          inTable: true,
          widthInput: 110,
          widthMenu: 120,
          fontSize: '12px',
          disabled: checkGroup(args.row.original) || args.row.original.isFromOtherProject,
          fastApplyData: true,
          isHideDropdown: true,
          checkNewVal: (initVal, newVal) => {
            if (!isDraft && initVal && newVal && newVal !== initVal
              &&  ['SUCCESS', 'MILESTONE_SUCCESS', 'MILESTONE_SUCCESS_WITH_COMMENT'].includes(getDictObj(dict.workStatus, newVal)?.code)
              && !checkMilestone(args.row.original)) {
              showModal(WORK_NEED_DOC_MESSAGE);
              return false;
            }

            return true;
          }
        })(args);
      },
      dictType: 'workStatus'
    },
    {
      Header: i18n.t('workDataRequest.dateStart'),
      width: 80,
      accessor: 'dateStart',
      Cell: (args) => {
        const dateClass = checkMilestone(args.row.original)
          ? dateEndClass(args.value, args.row.original, dict.workStatus)
          : dateStartClass(args.value, args.row.original, dict.workStatus);

        if (!isEditTable || editRowId !== args.row.id || !checkTask(args.row.original) || args.row.original.isExtreme) {
          const date = checkMilestone(args.row.original) ? args.row.original.dateEnd : args.value;
          return <span className={cx(dateClass,
            {'duration warn': checkMilestone(args.row.original)
                && isBeforeToday(args.row.original?.dateEnd)
                && !args.row.original.isSystem})}>
            {formatDate(date, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          minimumDate: getMinDate(args.row.original),
          maximumDate: args.row.original.dateEnd || getMaxDate(),
          isPortal: true,
          isHideIcon: true,
          className: cx(dateClass,
            {'duration warn': checkMilestone(args.row.original)
                && isBeforeToday(args.row.original?.dateEnd)
                && !args.row.original.isSystem}),
          fastApplyData: true,
          disabled: args.row.original.isFromOtherProject,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.dateEnd'),
      width: 80,
      accessor: 'dateEnd',
      Cell: (args) => {
        const item = args.row.original;

        if (!isEditTable || editRowId !== args.row.id || checkGroup(item) || item.isExtreme) {
          return <span className={cx(dateEndClass(args.value, item, dict.workStatus),
            {'duration warn': checkMilestone(item)
                && isBeforeToday(args.value)
                && !args.row.original.isSystem
            })}>
            {formatDate(args.value, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          minimumDate: !checkMilestone(item) && item.dateStart || getMinDate(item),
          maximumDate: getMaxDate(),
          isClearable: checkMilestone(args.row.original),
          isHideClear: true,
          isPortal: true,
          isInTable: true,
          isHideIcon: true,
          className: cx(dateEndClass(args.value, item, dict.workStatus),
            {'duration warn': checkMilestone(item)
                && isBeforeToday(args.value)
                && !args.row.original.isSystem}),
          fastApplyData: true,
          disabled: item.isFromOtherProject,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.dateEndFact'),
      width: 80,
      accessor: 'dateEndFact',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original) || args.row.original.isExtreme) {
          return <span className={cx(dateEndClass(args.value, args.row.original, dict.workStatus))}>
            {formatDate(args.value, FORMAT_DATE, null)}
          </span>;
        }

        return createEditable(DateSelect, {
          minimumDate: args.row.original.dateStart || getMinDate(args.row.original),
          maximumDate: getMaxDate(),
          isClearable: checkMilestone(args.row.original),
          isPortal: true,
          isInTable: true,
          isHideIcon: true,
          className: cx(dateEndClass(args.value, args.row.original, dict.workStatus)),
          fastApplyData: true,
          disabled: args.row.original.isFromOtherProject,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.dateEndInitial'),
      width: 80,
      accessor: 'dateEndInitial',
      Cell: (args) => {
        return formatDate(args.value, FORMAT_DATE, null);
      }
    },
    {
      Header: i18n.t('workDataRequest.duration'),
      width: 60,
      accessor: 'duration',
      Cell: ({ value, row }) => <span className='gantt-row-text'>{formatDays(checkMilestone(row.original) ? 0 : value)}</span>,
    },
    {
      Header: i18n.t('workDataRequest.calendarDurationShort'),
      width: 60,
      accessor: 'calendarDuration',
      Cell: (args) => <span className='gantt-row-text'>{formatDays(checkMilestone(args.row.original) ? 0 : args.value)}</span>
    },
    {
      Header: i18n.t('workDataRequest.responsible'),
      width: 140,
      accessor: 'responsible',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original) || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{getShortName(args.value?.displayName) || null}</span>
        }

        return createEditable(UserSearchInTable, {
          placeholder: "Выберите исполнителя",
          inTable: true,
          widthInput: 140,
          widthMenu: 150,
          fontSize: '12px',
          isShort: true,
          isHideDropdown: true,
          disabled: args.row.original.isFromOtherProject,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.responsibleRoleId'),
      width: 120,
      accessor: 'responsibleRoleId',
      Cell: (args) => {
        if (!isEditTable || editRowId !== args.row.id || checkGroup(args.row.original) || args.row.original.isExtreme) {
          return <span className='gantt-row-text'>{checkGroup(args.row.original) ? null : getDict(dict.roles, args.row.original.responsibleRoleId)}</span>
        }

        const projectTypeCode = getDictObj(dict.projectTypes, projectData.typeId)?.code;

        return createEditable(SelectDict, {
          customOptions: getProjectRoleOptionsList(dict.roles, projectTypeCode),
          placeholder: "Выберите роль",
          inTable: true,
          widthInput: 120,
          widthMenu: 130,
          fontSize: '12px',
          isHideDropdown: true,
          disabled: args.row.original.isFromOtherProject,
          fastApplyData: true,
        })(args);
      }
    },
    {
      Header: i18n.t('workDataRequest.dateUpdateStatus'),
      width: 80,
      accessor: 'dateUpdateStatus',
      Cell: ({ value }) => formatDate(value, FORMAT_DATE, null)
    },
  ]);

  return result;
};

const GanttTable = ({
  projectData,
  expanded,
  setExpanded,
  expandAllClick,
  isAllNotExpanded,
  expandAll,
  data,
  link,
  maxDepth,
  setData,
  openWorkId,
  setOpenWorkId,
  editRowId,
  setEditRowId,
  readonly,
  isEditRight,
  checked,
  setChecked,
  dataFlatten,
  scrollContainer,
}) => {
  const dict = useAppSelector(state => state.dict);
  const showModal = useShowModal();

  const onClickRow = (row, e) => {
    if (!readonly && isEditRight) {
      if (editRowId !== row.id && !row.original.isFromOtherProject) {
        setEditRowId(row.id);
        if (!e.nativeEvent.target.className) {
          return;
        }
        //Попытаемся поставить фокус на инпут
        setFocusTo(row.id, `.${e.nativeEvent.target.className}`, '.inputField');
      }
      return;
    }

    setOpenWorkId(row.id);
    return true;
  };

  const onToggleChecked = (workId: number) => {
    setChecked(prev => {
      return {
        ...prev,
        [workId]: !prev[workId]
      };
    });
  };

  const isCheckedAll = !isEmpty(data) && data.every(item => checked[item.id]);

  const onToggleCheckedAll = (isChecked) => {
    setChecked(dataFlatten.reduce((acc, item) => ({
      ...acc,
      [item.id]: isChecked,
    }), {}));
  };

  const focusKey = useRef(null);
  const columns = React.useMemo(() => {
    return getColumns({
      readonly,
      isEditRight,
      dict,
      projectData,
      showModal,
      maxDepth,
      setData,
      setExpanded,
      setOpenWorkId,
      setEditRowId,
      editRowId,
      expandAll,
      isAllNotExpanded
    });
  }, [maxDepth, editRowId, data, isAllNotExpanded]);

  return (
    <div className="gantt_table__container">
      <TableDragable
        data={data}
        link={link}
        columns={columns}
        expandedLocal={expanded}
        setExpanded={setExpanded}
        expandAllClick={expandAllClick}
        isAllNotExpanded={isAllNotExpanded}
        setData={setData}
        rowProps={getRowProps}
        readonly={readonly}
        isEditRight={isEditRight}
        onClickRow={onClickRow}
        scrollContainer={scrollContainer}
        props={{
          checked,
          onToggleChecked,
          isCheckedAll,
          onToggleCheckedAll,
          isAcceptEdit: !readonly && isEditRight && !openWorkId,
          focusKey,
        }}
      />
    </div>
  );

};

export default GanttTable;